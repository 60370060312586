<template>
  <main-layout>
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">DASHBOARD</h1>
    </div>
    <div class="row">
      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-primary text-uppercase
                    mb-1
                  "
                >
                  Brands
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ dashboard.brands }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-calendar fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-success text-uppercase
                    mb-1
                  "
                >
                  Services
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ dashboard.services }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-info text-uppercase mb-1"
                >
                  Clients
                </div>
                <div class="row no-gutters align-items-center">
                  <div class="col-auto">
                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                      {{ dashboard.clients }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pending Requests Card Example -->
      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="
                    text-xs
                    font-weight-bold
                    text-warning text-uppercase
                    mb-1
                  "
                >
                  Events
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ dashboard.events }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-comments fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content Row -->

    <div class="card">
      <div class="card-body">
        <h1>Welcome to your admin panel!</h1>
        <p class="lead mt-4">
          This admin panel will enable you to edit your website content to fit
          your needs at any time.
        </p>
        <p class="lead">
          At any time, if an issue occurs, please do not hesitate to contact the
          developers.
        </p>
      </div>
    </div>
    <div class="alert alert-warning mt-4">
      Events, page section editing and other features will be added shortly.
    </div>
  </main-layout>
</template>

<script>
import axios from "axios";
import MainLayout from "@/components/layout/MainLayout";
export default {
  name: "Dashboard",
  data() {
    return {
      dashboard: {
        brands: "",
        services: "",
        clients: "",
        events: "",
      },
    };
  },
  methods: {
    async getData() {
      try {
        const res = await axios.get("/api/v1/dashboard", {
          headers: {
            authorization: `bearer ${this.$store.state.token}`,
          },
        });

        if (res.status == 200) {
          this.dashboard = res.data.dashboard;
        }
      } catch (e) {
        //  --------- HANDLE ERROR
        console.log(e);
      }
    },
  },

  mounted() {
    this.getData();
  },
  components: {
    MainLayout,
  },
};
</script>

<style>
</style> 